export const symbolReplacements: [RegExp, string][] = [
  [/>=/g, '≥'],
  [/<=/g, '≤'],
  [/alpha-/g, 'α-'],
  [/beta-/g, 'β-'],
  [/µg/g, 'mcg'],
  [/µL/g, 'mcL'],
  [/µmol/g, 'mcmol'],
  [/µM/g, 'mcmol'],
  [/\*\*\+/g, '⁺'],

  [/\*\*10/g, '¹⁰'],
  [/\*\*11/g, '¹¹'],
  [/\*\*12/g, '¹²'],

  [/\*\*-1/g, '⁻¹'],
  [/\*\*2/g, '²'],
  [/\*\*-2/g, '⁻²'],
  [/\*\*3/g, '³'],
  [/\*\*-3/g, '⁻³'],
  [/\*\*4/g, '⁴'],
  [/\*\*5/g, '⁵'],
  [/\*\*6/g, '⁶'],
  [/\*\*7/g, '⁷'],
  [/\*\*8/g, '⁸'],
  [/\*\*9/g, '⁹'],

  [/–/g, '-'],
  [/“/g, '"'],
  [/”/g, '"'],

  [/CO2/g, 'CO₂'],
  [/H2O/g, 'H₂O'],
  [/SaO2/g, 'SaO₂'],
  [/SpO2/g, 'SpO₂'],
  [/PaO2/g, 'PaO₂'],
  [/FiO2/g, 'FiO₂'],
  [/α1/g, 'α₁'],
  [/α2/g, 'α₂'],
  [/β2/g, 'β₂'],

  [/5-HT3/g, '5-HT₃'],
  [/degrees C/g, '°C'],
  [/degrees F/g, '°F'],

  [/\?"\./g, '?"'],

  [/’/g, "'"],
  [/’/g, "'"],

  [/---/g, '-'],
  [/--/g, '-'],
  [/–/g, '-'],
  [/—/g, '-'],
  [/ —[^A-Za-z]/g, ' –$1'],
  [/ 100000 /, ' 100,000 '],
  [/ 10000 /, ' 10,000 '],
  [/ 1000 /, ' 1,000 '],
] as const
