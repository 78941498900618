export const organisationNames: [string, string[]][] = [
  ['aaaai', ['american academy of allergy, asthma & immunology']],
  ['aaap', ['american academy of addiction psychiatry']],
  ['aabb', ['american association of blood banks']],
  [
    'aacap',
    [
      'american academy of child and adolescent psychiatry',
      'american academy of child / adolescent psychiatry',
    ],
  ],
  ['aacc', ['american association for clinical chemistry']],
  ['aace', ['american association of clinical endocrinologists']],
  ['aaco', ['american association of certified orthoptists']],
  ['aact', ['american academy of clinical toxicology']],
  ['aad', ['american academy of dermatology']],
  ['aadsm', ['american academy of dental sleep medicine']],
  [
    'aadv',
    [
      'asian academy of dermatology and venereology',
      'asian academy of dermatology / venereology',
    ],
  ],
  ['aaes', ['american association of endocrine surgeons', 'aes']],
  ['aafp', ['american academy of family physicians']],
  [
    'aafprs',
    [
      'american academy of facial plastic and reconstructive surgery',
      'american academy of facial plastic / reconstructive surgery',
    ],
  ],
  [
    'aagbi',
    [
      'association of anaesthetists of great britain and ireland',
      'association of anesthetists of great britain / ireland',
    ],
  ],
  ['aagl', ['american association of gynecologic laparoscopists']],
  [
    'aahks',
    [
      'american association of hip and knee surgeons',
      'american association of hip / knee surgeons',
    ],
  ],
  [
    'aahpm',
    [
      'american academy of hospice and palliative medicine',
      'american academy of hospice / palliative medicine',
    ],
  ],
  [
    'aamuap',
    ['american association of manipulation under anesthesia providers'],
  ],
  ['aan', ['american academy of neurology']],
  ['aann', ['american association of neuroscience nurses']],
  ['aans', ['american association of neurological surgeons']],
  ['aao', ['american academy of ophthalmology']],
  [
    'aaos',
    [
      'american academy of orthopaedic surgeons',
      'american academy of orthopedic surgeons',
    ],
  ],
  ['aap', ['american academy of pediatrics']],
  ['aapa', ['american academy of physician assistants']],
  ['aapcc', ['american association of poison control centers']],
  ['aapd', ['american academy of pediatric dentistry']],
  ['aapm', ['american academy of pain medicine']],
  [
    'aapmr',
    [
      'american academy of physical medicine and rehabilitation',
      'american academy of physical medicine / rehabilitation',
    ],
  ],
  [
    'aapos',
    [
      'american association for pediatric ophthalmology and strabismus',
      'american association for pediatric ophthalmology / strabismus',
    ],
  ],
  ['aarc', ['american association for respiratory care']],
  [
    'aars',
    ['american acne and rosacea society', 'american acne / rosacea society'],
  ],
  ['aasld', ['american association for the study of liver diseases']],
  ['aasm', ['american academy of sleep medicine']],
  ['aast', ['american association for the surgery of trauma']],
  ['aats', ['american association for thoracic surgery']],
  ['aba', ['american burn association']],
  ['abc', ['association of black cardiologists']],
  ['abm', ['academy of breastfeeding medicine']],
  ['abn', ['association of british neurologists']],
  ['aca', ['american chiropractic association']],
  ['acaai', ['american college of allergy, asthma & immunology']],
  ['acc', ['acromegaly consensus conference']],
  ['accp', ['american college of chest physicians']],
  ['acd', ['australasian college of dermatologists']],
  ['ace', ['american college of endocrinology']],
  [
    'acemc',
    [
      'academy of emergency medicine and care',
      'academy of emergency medicine / care',
    ],
  ],
  ['acep', ['american college of emergency physicians']],
  [
    'acfas',
    [
      'american college of foot and ankle surgeons',
      'american college of foot / ankle surgeons',
    ],
  ],
  ['acg', ['american college of gastroenterology']],
  ['acg-sd', ['asian consensus group on seborrheic dermatitis']],
  ['aci alliance', ['american cochlear implant alliance']],
  ['acmg', ['american college of medical genetics']],
  ['acns', ['american clinical neurophysiology society']],
  [
    'acoem',
    [
      'american college of occupational and environmental medicine',
      'american college of occupational / environmental medicine',
    ],
  ],
  [
    'acog',
    [
      'american college of obstetricians and gynecologists',
      'american college of obstetricians / gynecologists',
    ],
  ],
  ['acoi', ['italian hospital surgeons association']],
  ['acp', ['american college of physicians']],
  [
    'acpgbi',
    [
      'association of coloproctology of great britain and ireland',
      'association of coloproctology of great britain / ireland',
    ],
  ],
  ['acpm', ['american college of preventive medicine']],
  ['acr', ['american college of rheumatology']],
  ['acs', ['american cancer society']],
  ['acsm', ['american college of sports medicine']],
  ['ada', ['asian dermatological association']],
  [
    'adarpef',
    [
      'french-speaking association of paediatric surgical intensivists',
      'french-speaking association of pediatric surgical intensivists',
    ],
  ],
  ['adqi', ['acute disease quality initiative']],
  [
    'ae-pcos',
    [
      'androgen excess and polycystic ovary syndrome society',
      'androgen excess / polycystic ovary syndrome society',
      'e-pcos',
    ],
  ],
  ['aeda', ['medical association of german allergologists', 'eda']],
  ['aep', ['spanish association of pediatrics']],
  [
    'aepc',
    [
      'association for european paediatric and congenital cardiology',
      'association for european pediatric / congenital cardiology',
      'epc',
    ],
  ],
  ['aes', ['american epilepsy society']],
  ['afef', ['french association for the study of the liver']],
  ['aga', ['american gastroenterological association']],
  ['agm', ['association of genitourinary medicine']],
  ['ags', ['american geriatrics society']],
  ['aha', ['american heart association']],
  [
    'ahrq',
    [
      'agency for healthcare research and quality',
      'agency for healthcare research / quality',
    ],
  ],
  ['ahs', ['american headache society']],
  ['ahsp', ['american society of health-system pharmacists']],
  [
    'ahuept',
    [
      'academy of hand and upper extremity physical therapy',
      'academy of hand / upper extremity physical therapy',
    ],
  ],
  ['aidsinfo', ['aidsinfo society']],
  ['aisf', ['italian association for the study of the liver']],
  ['aitf', ['amyloid imaging task force']],
  ['aium', ['american institute of ultrasound in medicine']],
  ['alat', ['latin american thoracic association']],
  ['alcs-wg', ['acute limb compartment syndrome working group']],
  ['alpha-1', ['alpha-1 foundation']],
  ['alssc', ['amyotrophic lateral sclerosis society of canada']],
  ['alta', ['advancing liver therapeutic approaches consortium']],
  [
    'amda',
    [
      'society for post-acute and long-term care medicine',
      'society for post-acute / long-term care medicine',
    ],
  ],
  ['ame', ['italian association of clinical endocrinologists']],
  ['ammi', ['association of medical microbiology / infectious disease canada']],
  ['amp', ['association for molecular pathology']],
  ['amsect', ['american society for extracorporeal technology']],
  ['amssm', ['american medical society for sports medicine']],
  [
    'and',
    ['academy of nutrition and dietetics', 'academy of nutrition / dietetics'],
  ],
  [
    'anms',
    [
      'american neurogastroenterology and motility society',
      'american neurogastroenterology / motility society',
    ],
  ],
  [
    'anzbms',
    [
      'australian and new zealand bone and mineral society',
      'australian / new zealand bone / mineral society',
    ],
  ],
  ['aoa', ['american osteopathic association']],
  [
    'aofas',
    [
      'american orthopaedic foot & ankle society',
      'american orthopedic foot & ankle society',
    ],
  ],
  [
    'aopt',
    [
      'academy of orthopaedic physical therapy',
      'academy of orthopedic physical therapy',
    ],
  ],
  [
    'aosd-cg',
    [
      "adult-onset still's disease consensus group",
      'adult-onset stills disease consensus group',
    ],
  ],
  ['aospine', ['ao spine foundation']],
  [
    'aossm',
    [
      'american orthopaedic society for sports medicine',
      'american orthopedic society for sports medicine',
    ],
  ],
  ['aota', ['american occupational therapy association, inc.']],
  ['apa', ['american pancreatic association']],
  ['apasl', ['asian pacific association for the study of the liver']],
  ['apce', ['academic pancreas centers of excellence']],
  ['apd', ['german working group for pediatric dermatology']],
  ['apha', ['american pharmacists association']],
  ['aphrs', ['asia pacific heart rhythm society']],
  ['apma', ['american podiatric medical association']],
  ['aps', ['american pain society']],
  ['apsa', ['american pediatric surgical association']],
  ['apsf', ['anesthesia patient safety foundation']],
  ['apsic', ['asia pacific society of infection control']],
  ['apta', ['american physical therapy association']],
  ['arc', ['amyloidosis research consortium']],
  [
    'aria',
    [
      'allergic rhinitis and its impact on asthma',
      'allergic rhinitis / its impact on asthma',
    ],
  ],
  ['asa', ['american society of anesthesiologists']],
  ['asam', ['american society of addiction medicine']],
  ['asas', ['assessment of spondyloarthritis international society']],
  ['asb', ['association of breast surgery']],
  [
    'asbmr',
    [
      'american society for bone and mineral research',
      'american society for bone / mineral research',
    ],
  ],
  [
    'asbmt',
    [
      'american society of blood and marrow transplantation',
      'american society of blood / marrow transplantation',
    ],
  ],
  ['asbs', ['american society of breast surgeons']],
  [
    'asccp',
    [
      'american society for colposcopy and cervical pathology',
      'american society for colposcopy / cervical pathology',
    ],
  ],
  ['asci-ceg', ['canadian expert group on acute spinal cord injury']],
  ['asco', ['american society of clinical oncology']],
  ['ascp', ['american society for clinical pathology']],
  [
    'ascrs',
    [
      'american society of colon and rectal surgeons',
      'american society of colon / rectal surgeons',
    ],
  ],
  ['asd', ['american society of dermatopathology']],
  ['asda', ['american society of dentist anesthesiologists']],
  ['ase', ['american society of echocardiography']],
  ['aser', ['american society of enhanced recovery']],
  ['asfa', ['american society for apheresis']],
  ['asge', ['american society for gastrointestinal endoscopy']],
  ['ash', ['american society of hematology']],
  ['ashp', ['american society of health-system pharmacists']],
  ['asipp', ['american society of interventional pain physicians']],
  ['asm', ['american society for microbiology']],
  [
    'asmbs',
    [
      'american society for metabolic and bariatric surgery',
      'american society for metabolic / bariatric surgery',
    ],
  ],
  ['asnc', ['american society of nuclear cardiology']],
  ['asnr', ['american society of neuroradiology']],
  ['aspc', ['american society for preventive cardiology']],
  [
    'aspen',
    [
      'american society for parenteral and enteral nutrition',
      'american society for parenteral / enteral nutrition',
    ],
  ],
  [
    'aspn',
    [
      'american society of pain and neuroscience',
      'american society of pain / neuroscience',
    ],
  ],
  ['asps', ['american society of plastic surgeons']],
  [
    'asra',
    [
      'american society of regional anesthesia and pain medicine',
      'american society of regional anesthesia / pain medicine',
    ],
  ],
  ['asrm', ['american society for reproductive medicine']],
  ['asro', ['american society for radiation oncology']],
  ['ast', ['american society of transplantation']],
  [
    'astmh',
    [
      'american society of tropical medicine and hygiene',
      'american society of tropical medicine / hygiene',
    ],
  ],
  ['astro', ['american society for radiation oncology']],
  ['at-wg', ['ataxia-telangiectasia working group']],
  ['ata', ['american thyroid association']],
  ['ats', ['american thoracic society']],
  [
    'atsdr',
    [
      'agency for toxic substances and disease registry',
      'agency for toxic substances / disease registry',
    ],
  ],
  ['aua', ['american urological association']],
  ['augs', ['american urogynecologic society']],
  ['aus', ['american urogynecologic society']],
  ['avf', ['american venous forum']],
  [
    'avls',
    [
      'american vein and lymphatic society',
      'american vein / lymphatic society',
    ],
  ],
  ['awmf', ['association of the scientific medical societies in germany']],
  ['bad', ['british association of dermatologists']],
  ['bap', ['british association for psychopharmacology']],
  [
    'bapn',
    [
      'british association of paediatric nephrology',
      'british association of pediatric nephrology',
    ],
  ],
  ['bash', ['british association for the study of headache']],
  [
    'bashh',
    [
      'british association for sexual health and hiv',
      'british association for sexual health / hiv',
    ],
  ],
  ['basl', ['british association for the study of the liver']],
  ['baus', ['british association of urological surgeons']],
  ['baveno vi', ['baveno vi consensus workshop']],
  ['baveno vii', ['baveno vii consensus workshop']],
  ['bcart', ['blood coagulation abnormalities research team']],
  ['bcd', ['association of coloproctologists in germany']],
  [
    'bcsh',
    [
      'british committee for standards in haematology',
      'british committee for standards in hematology',
    ],
  ],
  ['bdc', ['association of surgeons in germany']],
  [
    'bgcs',
    [
      'british gynaecological cancer society',
      'british gynecological cancer society',
    ],
  ],
  ['bgs', ['british geriatrics society']],
  ['bhiva', ['british hiv association']],
  ['bhpr', ['british health professionals in rheumatology']],
  ['bia', ['british infection association']],
  ['bigh', ['barcelona institute for global health']],
  ['bja', ['british journal of anaesthesia', 'british journal of anesthesia']],
  ['bjc', ['bone and joint canada', 'bone / joint canada']],
  ['bmj', ['british medical journal']],
  [
    'bmt ctn',
    [
      'blood and marrow transplant clinical trial group',
      'blood / marrow transplant clinical trial group',
    ],
  ],
  ['bng', ['british neurotrauma group']],
  [
    'boa',
    ['british orthopaedic association', 'british orthopedic association'],
  ],
  [
    'bomss',
    [
      'british obesity and metabolic surgery society',
      'british obesity / metabolic surgery society',
    ],
  ],
  [
    'bpaiig',
    [
      'british paediatric allergy, immunology and infection group',
      'british pediatric allergy, immunology / infection group',
    ],
  ],
  ['bpg', ['british photodermatology group']],
  ['bpigs', ['canadian best practice in general surgery group']],
  ['bsac', ['british society for antimicrobial chemotherapy']],
  [
    'bsaci',
    [
      'british society for allergy and clinical immunology',
      'british society for allergy / clinical immunology',
    ],
  ],
  [
    'bsbmt',
    [
      'british society of blood and marrow transplantation',
      'british society of blood / marrow transplantation',
    ],
  ],
  ['bsbr', ['british society of breast radiology']],
  ['bsc', ['brazilian society of cardiology']],
  ['bsd', ['brazilian society of dermatoloy']],
  ['bse', ['british society of echocardiography']],
  ['bsg', ['british sarcoma group']],
  [
    'bsge',
    [
      'british society for gynaecological endoscopy',
      'british society for gynecological endoscopy',
    ],
  ],
  [
    'bsh',
    ['british society for haematology', 'british society for hematology'],
  ],
  ['bso', ['brazilian society of otology']],
  [
    'bsoa',
    [
      'british society of orthopaedic anaesthetists',
      'british society of orthopedic anaesthetists',
    ],
  ],
  ['bsr', ['british society for rheumatology']],
  ['bssh', ['british society for surgery of the hand']],
  ['bssm', ['british society for sexual medicine']],
  [
    'bsug',
    ['british society of urogynaecology', 'british society of urogynecology'],
  ],
  ['btf', ['brain trauma foundation']],
  ['bts', ['british transplantation society']],
  ['bva', ['german professional association of ophthalmologists']],
  ['bvdd', ['professional association of german dermatologists']],
  ['bvf', ['association of gynecologists in germany']],
  ['bvkj', ['german professional association of pediatricians']],
  ['cacp', ['surgical working group for coloproctology']],
  ['caep', ['canadian association of emergency physicians', 'cep']],
  ['cag', ['canadian association of gastroenterologists']],
  ['caic', ['canadian association of interventional cardiology']],
  [
    'canmat',
    [
      'canadian network for mood and anxiety treatments',
      'canadian network for mood / anxiety treatments',
    ],
  ],
  ['canvasc', ['canadian vasculitis research network']],
  ['cap', ['college of american pathologists']],
  ['capcc', ['canadian association of poison control centres']],
  ['capo', ['canadian association of psychosocial oncology']],
  ['car', ['canadian association of radiologists']],
  [
    'catmat',
    [
      'committee to advise on tropical medicine and travel',
      'committee to advise on tropical medicine / travel',
    ],
  ],
  ['cca', ['canadian chiropractic association']],
  ['cccs', ['canadian critical care society']],
  ['ccg-cat', ['canadian consensus group on cancer-associated thrombosis']],
  [
    'ccg-vtec',
    ['canadian consensus group on venous thromboembolism in cancer'],
  ],
  ['ccgi', ['canadian chiropractic guideline initiative']],
  ['cco', ['cancer care ontario foundation']],
  ['ccp-ceg', ['canadian expert group on cannabinoids use in chronic pain']],
  ['ccs', ['canadian cardiovascular society']],
  ['ccsc', ['critical care societies collaborative']],
  ['ccsmh', ["canadian coalition for seniors' mental health"]],
  ['cctn', ['canadian cardiac transplant network']],
  ['cd-eg', ['chronic diarrhea expert group']],
  ['cdc', ['center for disease control']],
  ['cdcn', ['castleman disease collaborative network']],
  ['cebm', ['centre for evidence-based medicine']],
  ['cepo', ["comité de l'évolution de la pratique en oncologie"]],
  ['cer', ['mexican college of rheumatology']],
  ['cevf', ['central european vascular forum']],
  ['cff', ['cystic fibrosis foundation']],
  ['cfpc', ['college of family physicians of canada']],
  ['cgs', ['canadian geriatrics society']],
  ['chep', ['canadian hypertension education program']],
  ['chfs', ['canadian heart failure society']],
  ['choosing wisely', ['choosing wisely campaign']],
  ['chrs', ['canadian heart rhythm society']],
  ['chs', ['canadian headache society']],
  ['chsf', ['canadian hidradenitis suppurativa foundation']],
  [
    'cibmtr',
    [
      'center for international blood and marrow transplant registry',
      'center for international blood / marrow transplant registry',
    ],
  ],
  ['cihr', ['canadian institutes of health research']],
  ['cira', ['canadian interventional radiology association']],
  ['cirse', ['cardiovascular / interventional radiological society of europe']],
  ['ckcf', ['canadian kidney cancer forum']],
  ['cll-ceg', ['canadian expert group on chronic lymphocytic leukemia']],
  ['cma', ['canadian medical association']],
  ['cmaj', ['canadian medical association journal']],
  [
    'cmica',
    [
      'mexican college of allergy and clinical immunology',
      'mexican college of allergy / clinical immunology',
    ],
  ],
  [
    'cmrg-cgc',
    ['canadian myeloma research group consensus guideline consortium'],
  ],
  [
    'cngof',
    [
      'french national college of gynecologists and obstetricians',
      'french national college of gynecologists / obstetricians',
    ],
  ],
  ['cns', ['child neurology society']],
  ['cnsf', ['canadian neurological sciences federation']],
  ['coc', ['commission on cancer']],
  ['cos', ['canadian ophtalmological society']],
  ['cosa', ['clinical oncology society of australia']],
  ['cpca', ['canadian pediatric cardiology association']],
  ['cpdd', ['college on problems of drug dependence']],
  ['cpg', ['canadian practice guidelines']],
  ['cpic', ['clinical pharmacogenetics implementation consortium']],
  ['cps', ['canadian pain society']],
  ['cpstf', ['community preventive services task force']],
  ['cra', ['canadian rheumatology association']],
  ['crism', ['canadian research initiative in substance misuse']],
  [
    'csaci',
    [
      'canadian society for allergy and clinical immunology',
      'canadian society for allergy / clinical immunology',
    ],
  ],
  [
    'csanz',
    [
      'cardiac society of australia and new zealand',
      'cardiac society of australia / new zealand',
    ],
  ],
  ['csbpr', ['canadian stroke best practice recommendations']],
  ['csco', ['chinese society of clinical oncology']],
  ['csd', ['chinese society of dermatology']],
  [
    'cses',
    [
      'canadian shoulder and elbow society',
      'canadian shoulder / elbow society',
    ],
  ],
  ['csn', ['canadian society of nephrology']],
  ['csog', ['chinese society of obstetrics gynecology']],
  ['ctfh', ['canadian task force on hyperparathyroidism']],
  ['ctfphc', ['canadian task force on preventive health care']],
  ['cts', ['canadian thoracic society']],
  ['cua', ['canadian urological association']],
  ['cuog', ['canadian urological oncology group']],
  ['cvsa', ['cyclic vomiting syndrome association']],
  ['cwg-a', ['canadian working group on acne']],
  [
    'daab',
    [
      'german allergy and asthma association',
      'german allergy / asthma association',
    ],
  ],
  ['das', ['difficult airway society']],
  ['dcg-as', ['dutch consensus group on ankle sprain']],
  ['ddg', ['german society of dermatology']],
  ['decog', ['dermatological cooperative oncology group']],
  ['degro', ['german society of radiation oncology']],
  ['dest', ['digestive endoscopy society of taiwan']],
  [
    'dgai',
    [
      'german society of anaesthesiology and intensive care medicine',
      'german society of anesthesiology / intensive care medicine',
    ],
  ],
  [
    'dgaki',
    [
      'german society for allergology and clinical immunology',
      'german society for allergology / clinical immunology',
    ],
  ],
  [
    'dgaum',
    [
      'german society for occupational and environmental medicine e.v.',
      'german society for occupational / environmental medicine e.v.',
    ],
  ],
  ['dge', ['german society for nutrition']],
  [
    'dggg',
    [
      'german society of gynecology and obstetrics',
      'german society of gynecology / obstetrics',
    ],
  ],
  [
    'dghm',
    [
      'german society for hygiene and microbiology',
      'german society for hygiene / microbiology',
    ],
  ],
  [
    'dghnokhc',
    [
      'german society of oto-rhino-laryngology, head and neck surgery',
      'german society of oto-rhino-laryngology, head / neck surgery',
    ],
  ],
  [
    'dgho',
    [
      'german society of hematology and medical oncology',
      'german society of hematology / medical oncology',
    ],
  ],
  ['dgk', ['german society of coloproctology']],
  [
    'dgkj',
    [
      'german society for pediatric and adolescent medicine',
      'german society for pediatric / adolescent medicine',
    ],
  ],
  [
    'dgmkg',
    [
      'german society for oral and maxillofacial surgery',
      'german society for oral / maxillofacial surgery',
    ],
  ],
  ['dgn', ['german neurological society']],
  [
    'dgp',
    [
      'german society for pneumology and respiratory medicine',
      'german society for pneumology / respiratory medicine',
    ],
  ],
  ['dgpi', ['german society for pediatric infectious diseases']],
  [
    'dgppn',
    [
      'german society for psychiatry, psychotherapy and psychosomatics',
      'german society for psychiatry, psychotherapy / psychosomatics',
    ],
  ],
  ['dgpräc', ['german society of plastic, reconstructive / esthetic surgeons']],
  ['dgrh', ['german society of rheumatology']],
  ['dgu', ['german society of urology']],
  [
    'dgvs',
    [
      'german society of digestive and metabolic disorders',
      'german society of digestive / metabolic disorders',
    ],
  ],
  ['dha', ['danish health authority']],
  [
    'dhma',
    [
      'danish health and medicines authority',
      'danish health / medicines authority',
    ],
  ],
  ['dkg', ['german contact allergy group']],
  ['dmg', ['dutch multidisciplinary guideline']],
  ['dmis', ['dutch mesenteric ischemia study group']],
  ['dmykg', ['german-speaking mycological society']],
  ['dod', ['united states department of defense']],
  ['dog', ['german ophthalmological society']],
  ['dos', ['dutch orthopaedic society', 'dutch orthopedic society']],
  ['ds-cg', ['dumping syndrome consensus group']],
  ['dsd', ['danish society of dermatology']],
  [
    'dsohh',
    [
      'danish society of otorhinolaryngology, head and neck surgery',
      'danish society of otorhinolaryngology, head / neck surgery',
    ],
  ],
  ['dss', ['danish surgical society']],
  ['eaa', ['european academy of andrology']],
  [
    'eaaci',
    [
      'european academy of allergy and clinical immunology',
      'european academy of allergy / clinical immunology',
    ],
  ],
  ['eacts', ['european association for cardio-thoracic surgery']],
  ['eado', ['european association of dermato-oncology']],
  [
    'eadv',
    [
      'european academy of dermatology and venereology',
      'european academy of dermatology / venereology',
    ],
  ],
  ['eaes', ['european association for endoscopic surgery', 'ees']],
  [
    'eahad',
    [
      'european association for haemophilia and allied disorders',
      'european association for hemophilia / allied disorders',
    ],
  ],
  ['ean', ['european academy of neurology']],
  ['eanm', ['european association of nuclear medicine']],
  ['eano', ['european association of neuro-oncology']],
  ['eans', ['european association of neurosurgical societies']],
  [
    'eapcct',
    ['european association of poison centres / clinical toxicologists'],
  ],
  ['eapm', ['european association of perinatal medicine']],
  ['eas', ['european atherosclerosis society']],
  ['easd', ['european association for the study of diabetes']],
  ['easl', ['european association for the study of the liver']],
  ['easo', ['european association for the study of obesity']],
  ['east', ['eastern association for the surgery of trauma']],
  ['eau', ['european association of urology']],
  ['eaun', ['european association of urology nurses']],
  [
    'ebcog',
    [
      'european board of colleges of obstetrics and gynaecology',
      'european board of colleges of obstetrics / gynecology',
    ],
  ],
  [
    'ebjis',
    [
      'european bone and joint infection society',
      'european bone / joint infection society',
    ],
  ],
  [
    'ebmt',
    [
      'european society for blood and marrow transplantation',
      'european society for blood / marrow transplantation',
    ],
  ],
  ['ebv-wg', ['ebola virus disease working group']],
  ['ecas', ['european cardiac arrhythmia society']],
  [
    'ecco',
    [
      "european crohn's and colitis organisation",
      'european crohns / colitis organisation',
    ],
  ],
  ['ecfs', ['european cystic fibrosis society']],
  ['ecg-fm', ['egyptian consensus group on fibromyalgia']],
  ['ecg-rds', ['european consensus group on respiratory distress syndrome']],
  [
    'ecil',
    [
      'european conference on infections in leukaemia',
      'european conference on infections in leukemia',
    ],
  ],
  ['ecmm', ['european confederation of medical mycology']],
  ['ecnp', ['european college of neuropsychopharmacology']],
  ['econ', ['expert committee on nafld']],
  ['edf', ['european dermatology forum']],
  [
    'efisds',
    ['european federation international society for digestive surgery'],
  ],
  ['efns', ['european federation of neurological societies']],
  ['efsu', ['european federation of societies for ultrasound']],
  ['egs', ['european glaucoma society']],
  ['eha', ['european hematology association']],
  [
    'ehdn',
    [
      "european huntington's disease network",
      'european huntingtons disease network',
    ],
  ],
  [
    'ehmsg',
    [
      'european helicobacter and microbiota study group',
      'european helicobacter / microbiota study group',
    ],
  ],
  ['ehns', ['european head and neck society', 'european head / neck society']],
  ['ehra', ['european heart rhythm association']],
  ['ehs', ['european hip society']],
  ['ehtg', ['european hereditary tumour group']],
  ['ein', ['european insomnia network']],
  ['eks', ['european knee society']],
  ['eln', ['european leukemia net']],
  ['emcg', ['european microscopic colitis group']],
  ['emhg', ['european malignant hyperthermia group']],
  ['emn', ['european myeloma network']],
  ['empd-wg', ['extramammary paget disease working group']],
  ['endo-ern', ['european reference network on rare endocrine conditions']],
  ['enets', ['european neuroendocrine tumor society']],
  ['ens', ['european neurological society']],
  ['ensat', ['european network for the study of adrenal tumors']],
  ['eons', ['european oncology nursing society']],
  [
    'eortc',
    [
      'european organisation for research and treatment of cancer',
      'european organisation for research / treatment of cancer',
    ],
  ],
  ['epc', ['european pancreatic club']],
  ['epf', ['european pain federation']],
  [
    'epos2020',
    [
      'european position paper on rhinosinusitis and nasal polyps 2020',
      'european position paper on rhinosinusitis / nasal polyps 2020',
    ],
  ],
  ['epuap', ['european pressure ulcer advisory panel']],
  [
    'era-edta',
    [
      'european dialysis and transplant association',
      'european dialysis / transplant association',
    ],
  ],
  ['erass', ['enhanced recovery after surgery society']],
  ['erbp', ['european renal best practice foundation']],
  ['erc', ['european resuscitation council']],
  ['erknet', ['european rare kidney disease reference network']],
  [
    'ern genturis',
    ['european reference network on genetic tumour risk syndromes'],
  ],
  [
    'ern paedcan',
    [
      'european reference network for paediatric cancer',
      'european reference network for pediatric cancer',
      'ern pedcan',
    ],
  ],
  ['ers', ['european respiratory society']],
  ['esa', ['endocrine society of australia']],
  [
    'esaic',
    [
      'european society of anaesthesiology and intensive care',
      'european society of anesthesiology / intensive care',
    ],
  ],
  ['esc', ['european society of cardiology']],
  [
    'escmid',
    [
      'european society for microbiology and infectious diseases',
      'european society for microbiology / infectious diseases',
    ],
  ],
  ['escp', ['european society of coloproctology']],
  [
    'esdap',
    [
      'european society for dermatology and psychiatry',
      'european society for dermatology / psychiatry',
    ],
  ],
  ['ese', ['european society of endocrinology']],
  ['esem', ['european society of emergency medicine']],
  ['eses', ['european society of endocrine surgeons']],
  [
    'esgar',
    [
      'european society of gastrointestinal and abdominal radiology',
      'european society of gastrointestinal / abdominal radiology',
    ],
  ],
  ['esge', ['european society of gastrointestinal endoscopy']],
  [
    'esgo',
    [
      'european society of gynaecological oncology',
      'european society of gynecological oncology',
    ],
  ],
  ['esh', ['european society of hypertension']],
  [
    'eshre',
    [
      'european society of human reproduction and embryology',
      'european society of human reproduction / embryology',
    ],
  ],
  ['esicm', ['european society of intensive care medicine']],
  ['esmint', ['european society for minimally invasive neurological therapy']],
  ['esmo', ['european society of medical oncology']],
  [
    'esnm',
    [
      'european society for neurogastroenterology and motility',
      'european society for neurogastroenterology / motility',
    ],
  ],
  ['esnr', ['european society of neuroradiology']],
  ['eso', ['european stroke organisation']],
  ['esp', ['european society of pathology']],
  ['espd', ['european society of pediatric dermatology']],
  [
    'espen',
    [
      'european society for clinical nutrition and metabolism',
      'european society for clinical nutrition / metabolism',
    ],
  ],
  [
    'espku',
    [
      'european society for phenylketonuria and allied disorders',
      'european society for phenylketonuria / allied disorders',
    ],
  ],
  [
    'espu',
    [
      'european society for paediatric urology',
      'european society for pediatric urology',
    ],
  ],
  ['esr', ['european society of radiology']],
  ['esrs', ['european sleep research society']],
  ['esscd', ['european society for the study of coeliac disease']],
  ['essd', ['european society for swallowing disorders']],
  ['essm', ['european society for sexual medicine']],
  ['essts', ['european society for the study of tourette syndrome']],
  [
    'estes',
    [
      'european society for trauma and emergency surgery',
      'european society for trauma / emergency surgery',
    ],
  ],
  [
    'estro',
    [
      'european society for radiotherapy and oncology',
      'european society for radiotherapy / oncology',
    ],
  ],
  ['ests', ['european society of thoracic surgeons']],
  ['esur', ['european society of urogenital radiology']],
  ['esvm', ['european society for vascular medicine']],
  ['esvs', ['european society for vascular surgery']],
  ['eta', ['european thyroid association']],
  ['etfad', ['european task force on atopic dermatitis']],
  ['eugogo', ["european group of graves' orbitopathy"]],
  ['eular', ['european league against rheumatism']],
  ['euracan', ['european reference network on rare adult solid cancers']],
  ['euraps', ['european association of plastic surgeons']],
  ['eureos', ['european society of eosinophilic oesophagitis']],
  ['euretina', ['european society of retina specialists']],
  ['eurlssg', ['european restless legs syndrome study group']],
  ['eusem', ['european society for emergency medicine']],
  ['extrip', ['extracorporeal treatments in poisoning workgroup']],
  ['fcc', ['fight colorectal cancer']],
  ['fd-ecg', ['expert consensus group on fabry disease']],
  ['fes', ['french endocrine society']],
  ['ffcd', ['french federation of digestive oncology']],
  ['ficm', ['first international consensus meeting']],
  [
    'figo',
    [
      'international federation of gynecology and obstetrics',
      'international federation of gynecology / obstetrics',
    ],
  ],
  [
    'fipg',
    [
      'working group of french and israeli physicians and geneticists',
      'working group of french / isreli physicians / geneticists',
    ],
  ],
  ['fisf', ['fungal infection study forum']],
  [
    'fmatc',
    [
      'female and male athlete triad coalition',
      'female / male athlete triad coalition',
    ],
  ],
  ['fmf-eeg', ['egyptian expert group on familial mediterranean fever']],
  ['fmsd', ['finnish medical society duodecim']],
  ['fps', ['finnish pediatric society']],
  ['francogyn', ['french research group for oncologic gynecologic surgery']],
  ['frda-wg', ['friedreich ataxia working group']],
  ['fwg-a', ['french working group on acne']],
  ['gada', ['genetic aortic disorders association canada']],
  [
    'gain',
    [
      'guidelines and audit implementation network',
      'guidelines / audit implementation network',
    ],
  ],
  ['gaioa', ['global alliance to improve outcomes in acne']],
  ['gais', ['global alliance for infection in surgery']],
  [
    'ga²len',
    [
      'global allergy and asthma european network',
      'global allergy / asthma european network',
    ],
  ],
  ['gefa', ['french study group for large vessel vasculitis']],
  ['geis', ['spanish group for research on sarcomas']],
  ['gemnet', ['guidelines in emergency medicine network']],
  ['genturis', ['european reference network on genetic tumour risk syndromes']],
  ['gercor', ['multidisciplinary oncology cooperative group']],
  [
    'gesoc',
    [
      'spanish ocular surface and cornea group',
      'spanish ocular surface / cornea group',
    ],
  ],
  [
    'gfrup',
    [
      'french group for pediatric intensive care and emergencies',
      'french group for pediatric intensive care / emergencies',
    ],
  ],
  ['ghmf', ['german herpes management forum']],
  ['gihp', ['french working group on perioperative hemostasis']],
  ['gina', ['global initiative for asthma']],
  ['gitmo', ['italian group for bone marrow transplantation']],
  ['gkjr', ['german society of pediatric rheumatology']],
  ['gmdi', ['genetic metabolic dietitians international']],
  [
    'gmhs',
    [
      'german migraine and headache society',
      'german migraine / headache society',
    ],
  ],
  ['gng', ['german national guideline']],
  ['gold', ['global initiative for chronic obstructive lung disease']],
  [
    'gpge',
    [
      'german society for pediatric gastroenterology and nutrition',
      'german society for pediatric gastroenterology / nutrition',
    ],
  ],
  [
    'grade',
    ['grading of recommendations assessment, development / evaluation'],
  ],
  ['gsn', ['german society of neurology']],
  ['gurs', ['society of genitourinary reconstructive surgeons']],
  ['gus', ['german society of urology']],
  ['hasl', ['hellenic association for the study of the liver']],
  ['hcgp', ['health commission of guangdong province']],
  [
    'hd-eg',
    ["huntington's disease expert group", 'huntingtons disease expert group'],
  ],
  [
    'helpinkids',
    [
      'help eliminate pain in kids and adults',
      'help eliminate pain in kids / adults',
    ],
  ],
  ['hesmo', ['hellenic society of medical oncology']],
  ['hfsa', ['heart failure society of america']],
  [
    'hhs',
    [
      'u.s. department of health and human services',
      'u.s. department of health / human services',
    ],
  ],
  [
    'hht-wg',
    [
      'hereditary haemorrhagic telangiectasia working group',
      'hereditary hemorrhagic telangiectasia working group',
    ],
  ],
  ['his', ['healthcare infection society']],
  ['hivma', ['hiv medicine association']],
  ['hma', ['hiv medicine association']],
  ['home', ['harmonising outcome measures for eczema']],
  ['hpsg', ['hungarian pancreatic study group']],
  ['hrs', ['heart rhythm society']],
  ['hse', ['health service executive of ireland']],
  ['hsg', ['herniasurge group']],
  ['hsgo', ['hellenic society of gastroenterology']],
  ['hsp', ['hellenic psychiatric association']],
  ['iap', ['international association of pancreatology']],
  ['iapac', ['international association of providers of aids care']],
  ['ias', ['international aids society']],
  ['ic-aps', ['international consensus on antiphospholipid syndrome']],
  ['ic-os', ['international cardio-oncology society']],
  ['icaa', ['international congress on antiphospholipid antibodies']],
  [
    'icaall',
    [
      'international collaboration in asthma, allergy and immunology',
      'international collaboration in asthma, allergy / immunology',
    ],
  ],
  [
    'icc pbm',
    ['international consensus conference on patient blood management'],
  ],
  [
    'icfsr',
    [
      'international conference of frailty and sarcopenia research',
      'international conference of frailty / sarcopenia research',
    ],
  ],
  ['icg-sga', ['international consensus group on small for gestational age']],
  ['icon', ['international consensus on drug allergy']],
  ['icr', ['international consensus report']],
  ['icsm', ['international consultation on sexual medicine']],
  [
    'ictmg',
    ['international collaboration for transfusion medicine guidelines'],
  ],
  ['icud', ['international consultation on urological diseases']],
  ['idsa', ['infectious diseases society of america']],
  ['iehs', ['international endohernia society']],
  ['ifcn', ['international federation of clinical neurophysiology']],
  [
    'ifmss',
    [
      'international fetal medicine and surgery society',
      'international fetal medicine / surgery society',
    ],
  ],
  [
    'ilads',
    [
      'international lyme and associated diseases society',
      'international lyme / associated diseases society',
    ],
  ],
  ['ilae', ['international league against epilepsy', 'ile']],
  ['ilca', ['international liver cancer association']],
  ['ilcor', ['international liaison committee on resuscitation']],
  ['ilts', ['international liver transplantation society']],
  ['imdsf', ['international myelodysplastic syndromes foundation']],
  ['inpda', ['international niemann-pick disease alliance']],
  [
    'ipfngp',
    [
      'international pediatric fever and neutropenia guideline panel',
      'international pediatric fever / neutropenia guideline panel',
    ],
  ],
  ['ipfrn', ['international patellofemoral research network']],
  [
    'ipmds',
    [
      'international parkinson and movement disorder society',
      'international parkinson / movement disorder society',
    ],
  ],
  [
    'ipsis',
    [
      'international pain and spine intervention society',
      'international pain / spine intervention society',
    ],
  ],
  ['irlssg', ['international restless legs syndrome study group']],
  ['is igg4-rd', ['international symposium on igg4-related disease']],
  ['isa', ['international society of amyloidosis']],
  [
    'isaic',
    [
      'italian society of anesthesia and intensive care',
      'italian society of anesthesia / intensive care',
    ],
  ],
  ['isbd', ['international society for bipolar disorders']],
  ['isbi', ['international symposium on biomedical imaging']],
  ['iscd', ['international society for clinical densitometry']],
  ['iscvid', ['international society for cardiovascular infectious diseases']],
  ['isde', ['international society for diseases of the esophagus']],
  ['isge', ['international society for gynecologic endoscopy']],
  [
    'isham',
    [
      'international society for human and animal mycology',
      'international society for human / animal mycology',
    ],
  ],
  [
    'ishlt',
    [
      'international society for heart and lung transplantation',
      'international society for heart / lung transplantation',
    ],
  ],
  [
    'ismult',
    [
      'italian society of muscles, ligaments and tendons',
      'italian society of muscles, ligaments / tendons',
    ],
  ],
  ['isoo', ['international society of oral oncology']],
  ['isp', ['italian society of pediatrics']],
  [
    'ispad',
    [
      'international society for pediatric and adolescent diabetes',
      'international society for pediatric / adolescent diabetes',
    ],
  ],
  ['ispd', ['international society for peritoneal dialysis']],
  ['isr', ['italian society of rheumatology']],
  ['isshp', ['international society for the study of hypertension']],
  ['issm', ['international society for sexual medicine']],
  [
    'isth',
    [
      'international society on thrombosis and haemostasis',
      'international society on thrombosis / hemostasis',
    ],
  ],
  ['istm', ['international society of travel medicine']],
  ['istss', ['international society for traumatic stress studies']],
  ['isuog', ['international society of ultrasound in obstetrics / gynecology']],
  ['isup', ['international society of urological pathology']],
  [
    'itac',
    [
      'international initiative on thrombosis and cancer',
      'international initiative on thrombosis / cancer',
    ],
  ],
  ['its', ['irish thoracic society']],
  ['iua', ['international union of angiology']],
  ['iup', ['international union of phlebology']],
  ['iusti', ['international union against sexually transmitted infections']],
  ['ivdk', ['information network of dermatological clinics']],
  ['iwcll', ['international workshop on chronic lymphocytic leukemia']],
  ['iwgdf', ['international working group on the diabetic foot']],
  ['iwmap', ['international workshop on the management of asymptomatic phpt']],
  [
    'iwwm',
    [
      "international workshop on waldenstrom's macroglobulinemia",
      'international workshop on waldenstroms macroglobulinemia',
    ],
  ],
  ['jadsm', ['japanese academy of dental sleep medicine']],
  ['jaes', ['japan association of endocrine surgeons', 'jes']],
  ['jaid', ['japanese association for infectious disease']],
  ['jats', ['the japanese association for thoracic surgery']],
  ['jba', ['japan biliary association']],
  ['jbi', ['joanna briggs institute']],
  ['jcc', ['japanese college of cardiology']],
  ['jcr', ['japan college of rheumatology']],
  ['jcs', ['japanese circulation society']],
  ['jcu', ['journal of clinical urology']],
  ['jda', ['japanese dermatological association']],
  ['jes', ['japan endocrine society']],
  ['jga', ['japanese gastroenterological association']],
  ['jgca', ['japanese gastric cancer association']],
  ['jges', ['japan gastroenterological endoscopy society']],
  ['jgs', ['japan glaucoma society']],
  ['jhrs', ['japanese heart rhythm society']],
  ['jnc', ['joint national committee']],
  ['jns', ['japan neurosurgical society']],
  [
    'joa',
    ['japanese orthopaedic association', 'japanese orthopedic association'],
  ],
  ['jos', ['japan otological society']],
  ['jps', ['japan pancreas society']],
  ['jrs', ['japanese respiratory society']],
  ['jsa', ['japanese society of allergology']],
  ['jsbi', ['japanese society for burn injuries']],
  ['jsbpcc', ['japanese society of blood purification in critical care']],
  ['jsc', ['japanese society of chemotherapy']],
  ['jsco', ['japan society of clinical oncology']],
  ['jscvs', ['the japanese society for cardiovascular surgery']],
  ['jsdt', ['japanese society of dialysis therapy']],
  ['jsg', ['japanese society of gastroenterology']],
  ['jsge', ['japanese society of gastroenterology']],
  ['jshbps', ['japanese society of hepato-biliary-pancreatic surgery']],
  ['jsicm', ['japanese society of intensive care medicine']],
  ['jsn', ['japanese society of nephrology']],
  ['jsns', ['japanese society for neonatal screening']],
  ['jsoa', ['japanese society of ocular allergology']],
  ['jsoh', ['japan society for occupational health']],
  ['jspc', ['japanese society of pain clinicians']],
  ['jspe', ['japanese society for pediatric endocrinology']],
  ['jspn', ['japanese society of pediatric nephrology']],
  ['jspu', ['japanese society of pediatric urology']],
  ['jsrcm', ['japanese society of respiratory care medicine']],
  ['jss', ['japan stroke society']],
  ['jssr', ['japanese society of sleep research']],
  ['jsvs', ['the japanese society for vascular surgery']],
  ['jta', ['japan thyroid association']],
  ['jua', ['japanese urological association']],
  ['kda', ['korean dermatological association']],
  ['kdigo', ['kidney disease: improving global outcomes foundation']],
  ['kdoqi', ['national kidney foundation']],
  ['kha', ['kidney health australia']],
  ['khwg', ['korean working group on atypical hemolytic uremic syndrome']],
  ['kngf', ['royal dutch society for physiotherapy']],
  ['koa', ['korean orthopaedic association', 'korean orthopedic association']],
  ['ksc', ['korean society for chemotherapy']],
  ['ksccm', ['korean society of critical care medicine']],
  [
    'ksebpr',
    [
      'korean society for electrolyte and blood pressure research',
      'korean society for electrolyte / blood pressure research',
    ],
  ],
  ['ksid', ['korean society of infectious diseases']],
  ['ksn', ['korean society of nephrology']],
  ['lahrs', ['latin american heart rhythm society']],
  ['lcg', ['living concussion guidelines']],
  ['lmbp', ['laboratory medicine best practices']],
  ['lnni', ['lymphoedema network northern ireland']],
  ['maastricht iv/florence', ['maastricht iv/florence consensus report']],
  ['maastricht v/florence', ['maastricht v/florence consensus report']],
  ['mascc', ['multinational association of supportive care in cancer']],
  ['mcneil', ['mcneil consumer healthcare']],
  ['md-gwg', ['german working group on methamphetamine-related disorders']],
  ['md-wg', ['moyamoya disease working group']],
  ['mds-es', ['movement disorder society-european section']],
  ['mgfa', ['myasthenia gravis foundation of america']],
  ['mhf-cg', ['metabolic hyperferritinemia consensus group']],
  [
    'mhlw',
    [
      'ministry of health, labour and welfare of japan',
      'ministry of health, labour / welfare of japan',
    ],
  ],
  [
    'msmart',
    [
      'mayo stratification of myeloma and risk-adapted therapy',
      'mayo stratification of myeloma / risk-adapted therapy',
    ],
  ],
  ['msts', ['musculoskeletal tumor society']],
  ['n/a', ['no organisation']],
  ['nac', ['north american consensus']],
  [
    'naccs',
    [
      'neuro anaesthesia and critical care society',
      'neuro anesthesia / critical care society',
    ],
  ],
  ['naci', ['national advisory committee on immunization']],
  ['naftnet', ['north american fetal therapy network']],
  ['nams', ['north american menopause society']],
  ['nanets', ['north american neuroendocrine tumor society']],
  ['napaac', ['north american pediatric aplastic anemia consortium']],
  ['napbc', ['national accreditation program for breast centers']],
  ['nass', ['north american spine society']],
  ['nata', ["national athletic trainers' association"]],
  ['nccn', ['national comprehensive cancer network']],
  ['ncpcp', ['national council on potassium in clinical practice']],
  ['ncpdp', ['national council for prescription drug programs']],
  ['ncs', ['neurocritical care society']],
  ['nemos', ['neuromyelitis optica study group']],
  ['nfgap', ['national fibromyalgia guideline advisory panel']],
  ['nhf', ['national hemophilia foundation']],
  ['nhfa', ['national heart foundation of australia']],
  [
    'nhlbi',
    [
      'national heart, lung, and blood institute',
      'national heart, lung, / blood institute',
    ],
  ],
  [
    'nhmrc',
    [
      'national health and medical research council of australia',
      'national health / medical research council of australia',
    ],
  ],
  [
    'niaid',
    [
      'national institute of allergy and infectious diseases',
      'national institute of allergy / infectious diseases',
    ],
  ],
  [
    'nice',
    [
      'national institute for health and care excellence',
      'national institute for health / care excellence',
    ],
  ],
  ['niddk', ['national institute of diabetes / digestive / kidney diseases']],
  ['nih', ['national institutes of health']],
  ['nla', ['national lipid association']],
  ['nma', ['national medical association']],
  ['nmf', ['national marfan foundation']],
  ['nof', ['national osteoporosis foundation']],
  ['nov', ['dutch orthopaedic association', 'dutch orthopedic association']],
  ['npf', ['national psoriasis foundation']],
  ['npiap', ['national pressure injury advisory panel']],
  ['npis', ['national poisons information service']],
  ['nrn', ['nature reviews nephrology']],
  ['nsgc', ['national society of genetic counselors']],
  ['ntca', ['national tuberculosis controllers association']],
  ['ntn', ['neonatal transfusion network']],
  ['nugbccg', ['nonvariceal upper gi bleeding consensus conference group']],
  ['nvmdl', ['netherlands association of hepatogastroenterologists']],
  ['oarsi', ['osteoarthritis research society international']],
  [
    'oeggg',
    [
      'austrian society of gynecology and obstetrics',
      'austrian society of gynecology / obstetrics',
    ],
  ],
  ['ofsep', ['french observatory of multiple sclerosis']],
  ['oma', ['obesity medicine association']],
  ['onf', ['ontario neurotrauma foundation']],
  ['opa', ['office of population affairs']],
  ['orlsj', ['oto-rhino-laryngological society of japan']],
  ['ota', ['orthopaedic trauma association', 'orthopedic trauma association']],
  ['oxaleurope', ['european hyperoxaluria consortium']],
  [
    'paces',
    [
      'pediatric and congenital electrophysiology society',
      'pediatric / congenital electrophysiology society',
    ],
  ],
  ['paho', ['pan american health organization']],
  ['palicc-2', ['second pediatric acute lung injury consensus conference']],
  [
    'palisi',
    [
      'pediatric acute lung injury and sepsis investigators network',
      'pediatric acute lung injury / sepsis investigators network',
    ],
  ],
  ['panlar', ['pan-american league of associations of rheumatology']],
  ['pcna', ['preventive cardiovascular nurses association']],
  [
    'pdp-ecg',
    [
      "expert consensus group on parkinson's disease psychosis",
      'expert consensus group on parkinsons disease psychosis',
    ],
  ],
  ['peg', ['paul-ehrlich society for chemotherapy']],
  ['perg', ['prostatitis expert reference group']],
  ['pes', ['pediatric endocrine society']],
  ['pfapa-wg', ['pfapa syndrome working group']],
  ['pha', ['public health agency of northern ireland']],
  ['phac', ['public health agency of canada']],
  ['phe', ['public health england']],
  ['pids', ['pediatric infectious diseases society']],
  ['pidsp', ['pediatric infectious disease society of the philippines']],
  [
    'pnds',
    [
      'national diagnostic and care protocol',
      'national diagnostic / care protocol',
    ],
  ],
  ['pns', ['peripheral nerve society']],
  ['pogo', ['pediatric oncology group of ontario']],
  ['poqi', ['perioperative quality initiative']],
  [
    'posna',
    [
      'pediatric orthopaedic society of north america',
      'pediatric orthopedic society of north america',
    ],
  ],
  ['pppia', ['pan pacific pressure injury alliance']],
  ['pps', ['philippine pediatric society']],
  ['pres', ['pediatric rheumatology european society']],
  ['psci', ['praxis spinal cord institute']],
  ['pthscc', ['pediatric traumatic hemorrhagic shock consensus conference']],
  ['pts', ['pediatric trauma society']],
  ['puc', ['pediatric urologists of canada']],
  ['racgp', ['royal australian college of general practitioners']],
  ['racp', ['royal australasian college of physicians']],
  [
    'ranzcp',
    [
      'royal australian and new zealand college of psychiatrists',
      'royal australian / new zealand college of psychiatrists',
    ],
  ],
  ['rcem', ['royal college of emergency medicine']],
  [
    'rcog',
    [
      'royal college of obstetricians and gynaecologists',
      'royal college of obstetricians / gynecologists',
    ],
  ],
  ['rcophth', ['royal college of ophthalmologists']],
  ['rdi', ['german cancer society']],
  ['reg', ['respiratory effectiveness group']],
  ['rfc', ['rib fracture colloquium']],
  ['rlsf', ['restless legs syndrome foundation']],
  ['rnao', ["registered nurses' association of ontario"]],
  ['rs-ceg', ['canadian expert group on rhinosinusitis']],
  ['saa', ['spondylitis association of america']],
  ['sabm', ['society for the advancement of blood management']],
  ['saem', ['society for academic emergency medicine', 'sem']],
  [
    'sages',
    [
      'society of american gastrointestinal and endoscopic surgeons',
      'society of american gastrointestinal / endoscopic surgeons',
    ],
  ],
  [
    'saip',
    [
      'society of atherosclerosis imaging and prevention',
      'society of atherosclerosis imaging / prevention',
    ],
  ],
  ['samba', ['society for ambulatory anesthesia']],
  ['sart', ['society for assisted reproductive technology']],
  ['sas', ['safe airway society']],
  ['sba', ['spina bifida association']],
  ['sbns', ['society of british neurological surgeons']],
  ['sbop', ['brazilian pediatric ophthalmology society']],
  ['sca', ['society of cardiovascular anesthesiologists']],
  [
    'scai',
    [
      'society for cardiovascular angiography and interventions',
      'society for cardiovascular angiography / interventions',
    ],
  ],
  ['sccm', ['society of critical care medicine']],
  ['scct', ['society of cardiovascular computed tomography']],
  ['scmr', ['society for cardiovascular magnetic resonance']],
  ['sdh', ['society of dermatology hospitalists']],
  [
    'seaic',
    [
      'spanish society of allergology and clinical immunology',
      'spanish society of allergology / clinical immunology',
    ],
  ],
  [
    'sehh',
    [
      'spanish society of hematology and hemotherapy',
      'spanish society of hematology / hemotherapy',
    ],
  ],
  ['seimc', ['spanish society of clinical microbiology / infectious diseases']],
  ['seom', ['spanish association of medical oncology']],
  [
    'separ',
    [
      'spanish society of pneumology and thoracic surgery',
      'spanish society of pneumology / thoracic surgery',
    ],
  ],
  ['ser', ['spanish society of rheumatology']],
  [
    'serc',
    [
      'southeast regional newborn screening and genetics collaborative',
      'southeast regional newborn screening / genetics collaborative',
    ],
  ],
  ['sfa', ['french society of audiology']],
  [
    'sfar',
    [
      'french society of anesthesia and intensive care',
      'french society of anesthesia / intensive care',
    ],
  ],
  ['sfcd', ['french society of digestive surgery']],
  [
    'sfctcv',
    [
      'french society of thoracic and cardiovascular surgery',
      'french society of thoracic / cardiovascular surgery',
    ],
  ],
  ['sfed', ['french society of digestive endoscopy']],
  [
    'sfgg',
    [
      'french society of geriatrics and gerontology',
      'french society of geriatrics / gerontology',
    ],
  ],
  ['sfmu', ['french society of emergency medicine']],
  ['sfnr', ['french society of neuroradiology']],
  ['sforl', ['french society of otorhinolaryngology']],
  ['sfp', ['society of family planning']],
  [
    'sfpl',
    [
      'french society of phoniatrics and laryngology',
      'french society of phoniatrics / laryngology',
    ],
  ],
  ['sfrl', ['french language resuscitation society']],
  ['sfro', ['french society of oncological radiotherapy']],
  ['sfsep', ['french society of multiple sclerosis']],
  [
    'sgdv',
    [
      'swiss society for dermatology and venereology',
      'swiss society for dermatology / venereology',
    ],
  ],
  [
    'sggg',
    [
      'swiss society of gynecology and obstetrics',
      'swiss society of gynecology / obstetrics',
    ],
  ],
  ['sgim', ['society of general internal medicine']],
  ['sgo', ['society of gynecologic oncology']],
  ['sgs', ['society of gynecologic surgeons']],
  [
    'share initiative',
    ['single hub / access point for pediatric rheumatology in europe'],
  ],
  ['shea', ['society for healthcare epidemiology of america']],
  ['shm', ['society of hospital medicine']],
  ['shp', ['society of hospital medicine']],
  ['sia', ['italian society on alcohol']],
  [
    'siams',
    [
      'italian society of andrology and sexual medicine',
      'italian society of andrology / sexual medicine',
    ],
  ],
  ['siccr', ['italian society of colorectal surgery']],
  ['sicg', ['italian surgical society for elderly people']],
  ['sicg-fm', ['spanish interdisciplinary consensus group on fibromyalgia']],
  [
    'sicut',
    [
      'italian emergency surgery and trauma association',
      'italian emergency surgery / trauma association',
    ],
  ],
  [
    'sicve',
    [
      'italian society of vascular and endovascular surgery',
      'italian society of vascular / endovascular surgery',
    ],
  ],
  ['side', ['italian society of digestive endoscopy']],
  ['sidp', ['society of infectious diseases pharmacists']],
  ['sie', ['italian society of endocrinology']],
  ['sies', ['italian society of experimental hematology']],
  ['sifipac', ['italian society of surgical pathophysiology']],
  ['sige', ['italian society of gastroenterology']],
  ['sign', ['the scottish intercollegiate guidelines network']],
  [
    'sigo',
    [
      'italian society of gynaecology and obstetrics',
      'italian society of gynecology / obstetrics',
    ],
  ],
  ['siia', ['italian society of arterial hypertension']],
  ['sio', ['society for integrative oncology']],
  ['siog', ['international society of geriatric oncology']],
  ['sir', ['society of interventional radiology']],
  [
    'sirm',
    [
      'italian society of medical and interventional radiology',
      'italian society of medical / interventional radiology',
    ],
  ],
  ['sis', ['surgical infection society']],
  ['sis-e', ['surgical infection society europe']],
  [
    'siset',
    [
      'italian society for haemostasis and thrombosis',
      'italian society for hemostasis / thrombosis',
    ],
  ],
  ['sitc', ['society for immunotherapy of cancer']],
  ['siu', ['international society of urology']],
  ['siucp', ['italian unitary society of colon-proctology']],
  ['smfm', ['society for maternal-fetal medicine']],
  ['smsna', ['sexual medicine society of north america']],
  ['snfge', ['french national society of gastroenterology']],
  ['snis', ['society of neurointerventional surgery']],
  ['snlg', ['italian national guideline system']],
  [
    'snmmi',
    [
      'society of nuclear medicine and molecular imaging',
      'society of nuclear medicine / molecular imaging',
    ],
  ],
  ['sno', ['society for neuro-oncology']],
  [
    'soap',
    [
      'society for obstetric anesthesia and perinatology',
      'society for obstetric anesthesia / perinatology',
    ],
  ],
  [
    'sogc',
    [
      'society of obstetricians and gynaecologists of canada',
      'society of obstetricians / gynecologists of canada',
    ],
  ],
  ['solaece', ['latin american heart rhythm society', 'solece']],
  [
    'somanz',
    [
      'society of obstetric medicine of australia and new zealand',
      'society of obstetric medicine of australia / new zealand',
    ],
  ],
  ['sort', ['strength of recommendation taxonomy']],
  ['spa', ['society for pediatric anesthesia']],
  [
    'spartan',
    [
      'spondyloarthritis research and treatment network',
      'spondyloarthritis research / treatment network',
    ],
  ],
  ['sped', ['portuguese society of digestive endoscopy']],
  ['splf', ['french society of pulmonology']],
  ['splif', ['french society of infectious diseases']],
  ['srlf', ['french society of intensive care']],
  ['ssc', ['surviving sepsis campaign']],
  [
    'ssdv',
    [
      'swiss society of dermatology and venereology',
      'swiss society of dermatology / venereology',
    ],
  ],
  ['ssf', ["sjögren's syndrome foundation", 'sjögrens syndrome foundation']],
  ['sso', ['society of surgical oncology']],
  ['ssr', ['swedish society of rheumatology']],
  ['str', ['society of thoracic radiology']],
  ['sts', ['society of thoracic surgeons']],
  ['suo', ['society of urologic oncology']],
  ['svm', ['society for vascular medicine']],
  ['svn', ['society for vascular nursing']],
  ['svs', ['society for vascular surgery']],
  ['swab', ['dutch working party on antibiotic policy']],
  ['swf', ['sturge-weber foundation']],
  ['swg-ikd', ['spanish working group on inherited kidney diseases']],
  ['t2t', ['treat-to-target']],
  ['tage', ['thai association for gastrointestinal endoscopy']],
  ['td-ceg', ['canadian expert group on tic disorders']],
  ['tewg-ucd', ['trans-european working group on urea cycle disorders']],
  ['tppwg', ['treatment of paracetamol poisoning writing group']],
  [
    'tsanz',
    [
      'thoracic society of australia and new zealand',
      'thoracic society of australia / new zealand',
    ],
  ],
  ['tsci', ['tuberous sclerosis complex international']],
  [
    'ttcc',
    [
      'spanish group for the treatment of head and neck tumors',
      'spanish group for the treatment of head / neck tumors',
    ],
  ],
  ['tts', ['transplantation society']],
  ['uaa', ['urological association of asia']],
  ['ueg', ['united european gastroenterology']],
  ['uems', ['european union of medical specialists']],
  [
    'uhms',
    [
      'undersea and hyperbaric medical society',
      'undersea / hyperbaric medical society',
    ],
  ],
  [
    'ukhcdo',
    [
      'uk haemophilia centre doctors organization',
      'uk hemophilia centre doctors organization',
    ],
  ],
  ['ukhsa', ['united kingdom health security agency']],
  ['ukka', ['united kingdom kidney association']],
  ['ukms', ['united kingdom myeloma society']],
  ['uknmg', ['united kingdom national multidisciplinary guidelines']],
  ['um-cg', ['uveal melanoma consensus group']],
  ['ushsf', ['united states hidradenitis suppurativa foundation']],
  ['usmstf', ['us multi-society task force on colorectal cancer']],
  ['usphs', ['united states public health service']],
  ['uspstf', ['u.s. preventive services task force']],
  ['vascern', ['vascular european reference network']],
  ['vch', ['vancouver coastal health']],
  ['vdoe', ['german professional association of nutritional sciences']],
  [
    'vess',
    [
      'vascular and endovascular surgery society',
      'vascular / endovascular surgery society',
    ],
  ],
  ['wa-fai', ['warwick agreement on femoroacetabular impingement syndrome']],
  ['wabip', ['world association for bronchology / interventional pulmonology']],
  ['wao', ['world allergy organization']],
  ['wapm', ['world association of perinatal medicine']],
  ['wfg', ['world falls guidelines']],
  ['wfh', ['world federation of hemophilia']],
  ['wfsbp', ['world federation of societies of biological psychiatry']],
  [
    'wfumb',
    [
      'world federation for ultrasound in medicine and biology',
      'world federation for ultrasound in medicine / biology',
    ],
  ],
  ['who', ['world health organization']],
  ['whs', ['wound healing society']],
  ['wms', ['wilderness medical society']],
  [
    'wpsi',
    [
      "women's preventive services initiative",
      'womens preventive services initiative',
    ],
  ],
  ['wsacs', ['world society of the abdominal compartment syndrome']],
  ['wses', ['world society of emergency surgery']],
  ['wsis', ['world surgical infection society']],
  ['wta', ['western trauma association']],
  [
    'wuncmrt',
    [
      'wuhan university novel coronavirus management and research team',
      'wuhan university novel coronavirus management / research team',
    ],
  ],
  ['wvsipp', ['west virginia society for interventional pain physicians']],
  [
    'ögai',
    [
      'austrian society for allergology and immunology',
      'austrian society for allergology / immunology',
    ],
  ],
  [
    'ögdv',
    [
      'austrian society for dermatology and venereology',
      'austrian society for dermatology / venereology',
    ],
  ],
]
