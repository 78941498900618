export const wordsNotCapitalizedInTitles = [
  'a',
  'an',
  'and',
  'as',
  'at',
  'but',
  'by',
  'for',
  'from',
  'in',
  'into',
  'near',
  'nor',
  'of',
  'on',
  'onto',
  'or',
  'per',
  'so',
  'the',
  'to',
  'up',
  'via',
  'with',
  'we',
  'yet',
]
