import { memoize } from 'lodash'

import { properNouns } from '../constants/properNouns'
import { symbolReplacements } from '../constants/symbols'
import { applyBrandNameReplacements } from './normalize'

const sortedProperNouns = properNouns.sort((a, b) => b.length - a.length)
const properNounsMap = properNouns.reduce(
  (acc, noun) => {
    acc[noun.toLowerCase()] = noun
    return acc
  },
  {} as Record<string, string>,
)

export function recapitalizeProperNouns(originalStr: string): string {
  const words = originalStr.split(/\s+/)

  const maskedWords = words.map((word, index) => {
    for (const noun of sortedProperNouns) {
      if (word.toLowerCase() === noun.toLowerCase()) {
        return `__MASK_${index}__`
      }
    }
    return word
  })

  const maskedStr = maskedWords.join(' ')

  let result = maskedStr
  for (let i = 0; i < words.length; i++) {
    if (maskedWords[i]?.startsWith('__MASK_')) {
      const originalWord = words[i]
      const lowercaseWord = originalWord?.toLowerCase()
      if (!lowercaseWord) {
        continue
      }
      const noun = properNounsMap[lowercaseWord]
      result = result.replace(`__MASK_${i}__`, noun ?? '')
    }
  }

  return result
}

/** Decapitalizes a string, except for proper nouns */
export const decapitalize = memoize(function decapitalize(
  originalStr: string,
): string {
  if (
    originalStr === null ||
    originalStr === undefined ||
    originalStr.length === 0
  ) {
    return ''
  }

  if (originalStr.length === 1) {
    return originalStr.toLowerCase()
  }

  const str = originalStr.trim()

  // Check if the first word is a proper noun
  // If true, overrides other rules (e.g. "QT")
  const firstWord = str.split(' ')[0] ?? ''
  if (properNounsMap[firstWord.toLowerCase()]) {
    return str
  }

  const c0 = str[0]
  const c1 = str[1]

  if (str.length < 2 || c0 === undefined || c1 === undefined) {
    // If string is 1 character, make it uppercase (abbreviation)
    return str.toUpperCase()
  } else if (c1.toUpperCase() === c1 && c1 !== ' ') {
    // If 2nd character is uppercase, don't change anything (abbreviation)
    return str
  } else {
    // Otherwise, lowercase first (normal word)
    const decap = str[0]?.toLowerCase() + str.slice(1, str.length)
    // Recapitalize proper nouns
    const recap = recapitalizeProperNouns(decap)
    return recap
  }
})

export const ellipsizeText = (text: string, length: number) => {
  return text.length > length ? `${text.slice(0, length)}...` : text
}

export const capitalize = (s: string) => {
  const s2 = (s ?? '').trim()
  const c0 = s2[0]
  if (c0 === undefined) {
    return ''
  }
  const s3 = c0.toUpperCase() + (s2.length > 1 ? s2.slice(1) : '')
  return s3.replace(/-([a-z])$/, (_x0, x1) => `-${x1.toUpperCase()}`)
}

export const removeTrailingPeriod = (str: string): string => {
  return str.replace(/\.$/, '')
}

export const removeTrailingSymbols = (str: string): string => {
  return str.replace(/[.†,;:]$/, '')
}

export function replaceSymbols(str: string): string {
  if (!str) {
    return str
  }
  for (const [exp, symbol] of symbolReplacements) {
    str = str.replace(exp, symbol)
  }

  return str
}

export const formatDrugName = (drug: {
  releaseFormat: null | string
  salts: string[]
  substanceName: string
}) => {
  const substances = drug.substanceName.split('/').map((x) => x.trim())
  const salts = drug.salts.map((x) => decapitalize(x.trim()))
  const releaseFormat =
    drug.releaseFormat && drug.releaseFormat !== 'IR'
      ? ` ${drug.releaseFormat}`
      : ''

  return (
    substances
      .map((substance, i) => {
        return salts[i] ? `${substance} ${salts[i]}` : substance
      })
      .join(' / ') + releaseFormat
  )
}

export const formatDrugBrandName = (name: string) => {
  return applyBrandNameReplacements(
    name
      .split('-')
      .map((x) => capitalize(x))
      .join('-')
      .replace(/^ec-/i, 'EC-')
      .replace(/^mpm $/i, 'MPM ')
      .replace(/-dur$/i, '-DUR')
      .replace(/-bid$/i, '-BID')
      .replace(/-con$/i, '-CON')
      .replace(/-hs$/i, '-HS')
      .replace(/ ds$/i, ' DS')
      .replace(/ pfs$/i, ' PFS')
      .replace(/ odt$/i, ' ODT')
      .replace(/ xr-odt$/i, ' XR-ODT')
      .replace(/ lar$/i, ' LAR')
      .replace(/ hct$/i, ' HCT')
      .replace(/ at$/i, ' AT')
      .replace(/ tr$/i, ' TR')
      .replace(/ -provera$/i, ' -Provera')
      .replace(/ -subq $/i, ' -SubQ ')
      .replace(/ iii$/i, ' III')
      .replace(/ hfa$/i, ' HFA')
      .replace(/ prestab$/i, ' PresTab')
      .replace(/ ambisome$/i, ' AmBisome')
      .replace(/ proair$/i, ' ProAir')
      .replace(/ respiclick$/i, ' RespiClick')
      .replace(/ aq$/i, ' AQ'),
  )
}

export function ensureTrailing(str: string, trailing: string): string {
  str = str.trim()
  if (str.length === 0) return ''

  if (str[str.length - 1] !== trailing) {
    return (str + trailing).trim()
  }

  return str.trim()
}

export function ensureNoTrailing(str: string, trailing: string): string {
  if (str.slice(str.length - trailing.length) === trailing) {
    return str.slice(0, str.length - trailing.length).trim()
  }

  return str
}

export function escapeHtml(unsafe: string) {
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
}

export const unescapeHtml = (x: string) => {
  return x
    .replaceAll('&gt;', '>')
    .replaceAll('&lt;', '<')
    .replaceAll('&quot;', '"')
    .replaceAll('&#039;', "'")
    .replaceAll(/&amp;/g, '&')
}
